exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-conduct-js": () => import("./../../../src/pages/business-conduct.js" /* webpackChunkName: "component---src-pages-business-conduct-js" */),
  "component---src-pages-car-loan-calculator-js": () => import("./../../../src/pages/car-loan-calculator.js" /* webpackChunkName: "component---src-pages-car-loan-calculator-js" */),
  "component---src-pages-car-loan-js": () => import("./../../../src/pages/car-loan.js" /* webpackChunkName: "component---src-pages-car-loan-js" */),
  "component---src-pages-car-valuation-singapore-js": () => import("./../../../src/pages/car-valuation-singapore.js" /* webpackChunkName: "component---src-pages-car-valuation-singapore-js" */),
  "component---src-pages-carousell-certified-cars-singapore-index-js": () => import("./../../../src/pages/carousell-certified-cars-singapore/index.js" /* webpackChunkName: "component---src-pages-carousell-certified-cars-singapore-index-js" */),
  "component---src-pages-coe-history-index-js": () => import("./../../../src/pages/coe-history/index.js" /* webpackChunkName: "component---src-pages-coe-history-index-js" */),
  "component---src-pages-coe-renewal-loan-js": () => import("./../../../src/pages/coe-renewal-loan.js" /* webpackChunkName: "component---src-pages-coe-renewal-loan-js" */),
  "component---src-pages-consign-a-car-js": () => import("./../../../src/pages/consign-a-car.js" /* webpackChunkName: "component---src-pages-consign-a-car-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-coty-2022-about-js": () => import("./../../../src/pages/coty-2022/about.js" /* webpackChunkName: "component---src-pages-coty-2022-about-js" */),
  "component---src-pages-coty-2022-nominees-js": () => import("./../../../src/pages/coty-2022/nominees.js" /* webpackChunkName: "component---src-pages-coty-2022-nominees-js" */),
  "component---src-pages-coty-2022-winners-js": () => import("./../../../src/pages/coty-2022/winners.js" /* webpackChunkName: "component---src-pages-coty-2022-winners-js" */),
  "component---src-pages-coty-2023-about-js": () => import("./../../../src/pages/coty-2023/about.js" /* webpackChunkName: "component---src-pages-coty-2023-about-js" */),
  "component---src-pages-coty-2023-automotive-specialist-award-js": () => import("./../../../src/pages/coty-2023/automotive-specialist-award.js" /* webpackChunkName: "component---src-pages-coty-2023-automotive-specialist-award-js" */),
  "component---src-pages-coty-2023-winners-js": () => import("./../../../src/pages/coty-2023/winners.js" /* webpackChunkName: "component---src-pages-coty-2023-winners-js" */),
  "component---src-pages-coty-2024-index-js": () => import("./../../../src/pages/coty-2024/index.js" /* webpackChunkName: "component---src-pages-coty-2024-index-js" */),
  "component---src-pages-coty-2024-winners-js": () => import("./../../../src/pages/coty-2024/winners.js" /* webpackChunkName: "component---src-pages-coty-2024-winners-js" */),
  "component---src-pages-depreciation-calculator-js": () => import("./../../../src/pages/depreciation-calculator.js" /* webpackChunkName: "component---src-pages-depreciation-calculator-js" */),
  "component---src-pages-deregister-your-car-js": () => import("./../../../src/pages/deregister-your-car.js" /* webpackChunkName: "component---src-pages-deregister-your-car-js" */),
  "component---src-pages-ev-credits-giveaway-terms-js": () => import("./../../../src/pages/ev-credits-giveaway-terms.js" /* webpackChunkName: "component---src-pages-ev-credits-giveaway-terms-js" */),
  "component---src-pages-fuel-voucher-terms-js": () => import("./../../../src/pages/fuel-voucher-terms.js" /* webpackChunkName: "component---src-pages-fuel-voucher-terms-js" */),
  "component---src-pages-giveaway-js": () => import("./../../../src/pages/giveaway.js" /* webpackChunkName: "component---src-pages-giveaway-js" */),
  "component---src-pages-guess-the-coe-js": () => import("./../../../src/pages/guess-the-coe.js" /* webpackChunkName: "component---src-pages-guess-the-coe-js" */),
  "component---src-pages-guess-the-coe-terms-js": () => import("./../../../src/pages/guess-the-coe-terms.js" /* webpackChunkName: "component---src-pages-guess-the-coe-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instant-giveaway-js": () => import("./../../../src/pages/instant-giveaway.js" /* webpackChunkName: "component---src-pages-instant-giveaway-js" */),
  "component---src-pages-motor-car-insurance-singapore-js": () => import("./../../../src/pages/motor-car-insurance-singapore.js" /* webpackChunkName: "component---src-pages-motor-car-insurance-singapore-js" */),
  "component---src-pages-new-cars-price-lists-js": () => import("./../../../src/pages/new-cars/price-lists.js" /* webpackChunkName: "component---src-pages-new-cars-price-lists-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-scrap-car-singapore-js": () => import("./../../../src/pages/scrap-car-singapore.js" /* webpackChunkName: "component---src-pages-scrap-car-singapore-js" */),
  "component---src-pages-sell-my-car-online-to-dealer-singapore-js": () => import("./../../../src/pages/sell-my-car-online-to-dealer-singapore.js" /* webpackChunkName: "component---src-pages-sell-my-car-online-to-dealer-singapore-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-trade-in-your-car-js": () => import("./../../../src/pages/trade-in-your-car.js" /* webpackChunkName: "component---src-pages-trade-in-your-car-js" */),
  "component---src-pages-vouchers-giveaway-terms-js": () => import("./../../../src/pages/vouchers-giveaway-terms.js" /* webpackChunkName: "component---src-pages-vouchers-giveaway-terms-js" */),
  "component---src-templates-article-category-v-3-js": () => import("./../../../src/templates/article-category-v3.js" /* webpackChunkName: "component---src-templates-article-category-v-3-js" */),
  "component---src-templates-article-detail-article-detail-js": () => import("./../../../src/templates/article-detail/article-detail.js" /* webpackChunkName: "component---src-templates-article-detail-article-detail-js" */),
  "component---src-templates-article-level-3-js": () => import("./../../../src/templates/article-level3.js" /* webpackChunkName: "component---src-templates-article-level-3-js" */),
  "component---src-templates-article-search-js": () => import("./../../../src/templates/article-search.js" /* webpackChunkName: "component---src-templates-article-search-js" */),
  "component---src-templates-articles-home-js": () => import("./../../../src/templates/articles-home.js" /* webpackChunkName: "component---src-templates-articles-home-js" */),
  "component---src-templates-make-profile-js": () => import("./../../../src/templates/make-profile.js" /* webpackChunkName: "component---src-templates-make-profile-js" */)
}

